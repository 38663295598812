<template>
  <section id="courses" class="ls">
    <div class="row">
      <div class="col-md-12" v-if="courses.length > 0">
        <!-- start portfolio item -->
        <carousel
            :items="4"
            :nav="false"
            :margin="8"
            :dots="false"
            :autoplay="true"
            :responsive="{0: {items: 1}, 576: {items: 2}, 992: {items: 3}, 1200: {items: 4}}"
            :autoplayHoverPause="true"
        >
          <div class="courses-item" v-for="(course, index) in courses" :key="index">
            <div class="media courses">
              <img :src="course.photo_web_url" alt="">
              <div class="media-body cs">
                <div class="courses-header">
                  <div class="plan-name">
                    <h4>
                      {{ course.name }}
                    </h4>
                  </div>
                  <div class="price-wrap color-dark">
                    <span class="plan-price">{{ parseInt(course.price) }}</span>
                    <span class="plan-sign">USD</span>
                  </div>
                </div>
                <p class="number-lessons mt-17">{{ course.lessons_count }} Lecciones</p>
                <p class="description-lessons fs-20">{{ course.short_description }}</p>
                <div class="button-section">
                  <a :href="'curso/' + course.id" class="btn btn-outline-maincolor">Ver Más</a>
                </div>
              </div>
            </div>
          </div>
        </carousel>
        <!-- end portfolio item -->
      </div>
    </div>
  </section>
</template>

<script>
  import carousel from 'vue-owl-carousel'
  export default {
    name: "CourseSlider",
    components: { carousel },
    data: () => ({
      courses: [],
    }),
    mounted () {
      this.get_courses()
    },
    methods: {
      get_courses () {
        axios.get('api/course', {
          params: {sortBy: 'price', descending: 'false'}
        })
        .then(response => {
          this.courses = response.data.data
        })
        .catch(error => {

        })
      }
    }
  }
</script>

<style scoped>

</style>