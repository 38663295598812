<template>
  <section class="ls s-py-20 s-py-xl-70">
    <div class="container">
      <div class="row mobile-padding-normal c-gutter-120">
        <div class="col-md-12 col-lg-7">
          <h3 class="after-title pt-40"><span class="color-main">Detalles del</span> Curso</h3>
          <p v-html="course_data.description"></p>
          <h3 class="after-title"><span class="color-main">Requisitos del </span> Curso</h3>
          <ul class="list-styled">
            <li v-for="requirement in course_data.requirements">{{ requirement.name }}</li>
          </ul>
          <p>Si desea ampliar ha alguna categoría que no este en la página, comunicarse a los números de contacto. <br>
              <b><a href="tel: +507 394-7543">+507 394-7543</a></b>, <b><a href="tel: +507 394-7546">+507 394-7546</a></b> o <b><a href="tel: +507 6452-9672">+507 6452-9672</a></b>
          </p>
        </div>
        <div class="divider-50 d-md-block d-lg-none"></div>
        <div class="col-md-12 col-lg-5">
          <div class="divider-40 divider-md-0 divider-xl-0"></div>
          <img :src="course_data.photo_web_url" alt="">
          <h5 class="mt-3">{{ course_data.name }}</h5>
          <div class="divider-30 divider-xl-30"></div>
          <div class="icon-box">
            <div class="media">
              <div class="icon-styled color-main fs-40">
                <i class="ico icon-event"></i>
              </div>
              <div class="media-body">
                <h6 class="fw-300">
                  Certificado de Curso Online
                </h6>
              </div>
            </div>
          </div>
          <div class="icon-box mt-50">
            <div class="media">
              <div class="icon-styled color-main fs-40">
                <i class="ico icon-helping"></i>
              </div>
              <div class="media-body">
                <h6 class="fw-300">
                  Cuenta 24/7
                </h6>
                <p>
                  Cuenta en línea <br> acceso 24/7
                </p>
              </div>
            </div>
          </div>
          <buy-course-btn style="margin-top: 32px;" :course_id="course_data.id"></buy-course-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CourseDetailTitle",
  props: ['course_id'],
  data: () => ({
    course_data: {}
  }),
  mounted () {
    this.get_course()
  },
  methods: {
    get_course(){
      axios.get('api/course/' + this.course_id)
      .then(response => {
        this.course_data = response.data;
      })
      .catch(error => {
        if (error.response.status === 500){
          window.location.href = '/cursos'
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
