<template>
  <div class="preloader">
      <div class="preloader_css"></div>
  </div>
</template>

<script>
export default {
  name: "PreloaderComponent",
  mounted () {
    this.flash_preloader();

    this.$root.$on('show_preloader', () => {
      this.show_preloader();
    })

    this.$root.$on('flash_preloader', () => {
      this.flash_preloader();
    })

  },
  methods: {
    flash_preloader(){
      $(".preloader_img, .preloader_css").fadeOut(800);
      setTimeout(function () {
        $(".preloader").fadeOut(800);
      }, 200);
    },
    show_preloader(){
      $(".preloader_img, .preloader_css").show();
      setTimeout(function () {
        $(".preloader").show();
      }, 200);
    }
  }
}
</script>