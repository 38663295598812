<template>
  <button type="button" @click.prevent.stop="set_session_checkout" class="btn btn-maincolor">Adquirir Curso</button>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    name: "BuyCourseBtn",
    props: ['course_id'],
    methods: {
      set_session_checkout () {
        Cookies.set('course_selected', this.course_id)
        window.location.href = '/checkout'
      }
    }
  }
</script>

<style scoped>

</style>