<template>
  <div>
    <section class="ls s-py-50" v-if="!payment_success">
      <div class="container">
        <div class="row">
          <div class="d-none d-lg-block divider-70"></div>
          <div v-if="!course_data">
            <div class="alert alert-warning col-md-12" role="alert">
              No haz seleccionado ningún curso
            </div>
          </div>
          <main class="col-lg-12" v-else>
            <form name="checkout" method="post" class="checkout woocommerce-checkout" @submit.prevent="make_payment" novalidate="novalidate">
              <div class="col2-set" id="customer_details">
                <div class="col-1">
                  <div class="woocommerce-billing-fields">
                    <h3>{{ !Object.keys(user_data).length ? 'Registra tus datos' : 'Información Personal' }}</h3>
                    <div class="woocommerce-billing-fields__field-wrapper">
                      <div class="woocommerce-NoticeGroup woocommerce-NoticeGroup-checkout" v-if="payment_errors.errors">
                        <ul class="woocommerce-error" style="border-left: 5px solid #f45959;background-color: rgb(255 0 0 / 7%);color: #242327;">
                          <li v-for="error in payment_errors.errors">{{ error[0] }}</li>
                        </ul>
                      </div>
                      <div class="woocommerce-info" v-if="!Object.keys(user_data).length">¿Ya tiene una cuenta?
                        <a href="#" @click.prevent="open_login_modal">Iniciar Sesión</a>
                      </div>
                      <div class="row mb-2" v-if="Object.keys(user_data).length">
                        <div class="col-md-12">
                          <p>Nombre: <strong>{{ user_data.name }}</strong></p>
                          <p>Email: <strong>{{ user_data.email }}</strong></p>
                          <p>Celular: <strong>{{ user_data.user_profile.phone }}</strong></p>
                          <p>Cédula / Pasaporte: <strong>{{ user_data.user_profile.identity_card }}</strong></p>
                          <a href="#" class="btn btn-danger" @click.prevent="logout">Cerrar Sesión</a>
                        </div>
                      </div>
                      <template v-if="!Object.keys(user_data).length">
                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="form-check form-check-inline">
                              <label for="first_time"><input v-model="payment_data.is_first_time" type="radio" name="is_first_time" id="first_time" :value="true"> Primera Vez</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <label for="extension"><input v-model="payment_data.is_first_time" type="radio" name="is_first_time" id="extension" :value="false"> Ampliación</label>
                            </div>
                          </div>
                        </div>
                        <p class="form-row form-row-first validate-required woocommerce-invalid woocommerce-invalid-required-field">
                          <select id="country" v-model="payment_data.country_id">
                            <option :value="country.id" v-for="country in options_countries">{{ country.label }}</option>
                          </select>
                        </p>
                        <p class="form-row form-row-last validate-required">
                          <input type="text" class="input-text" id="identity_card" v-model="payment_data.identity_card" placeholder="Ingrese cédula o pasaporte">
                        </p>
                        <p class="form-row form-row-first validate-required woocommerce-invalid woocommerce-invalid-required-field" id="billing_first_name_field" data-priority="10">
                          <input type="text" class="input-text" id="billing_first_name" v-model="payment_data.first_name" placeholder="Ingrese su primer y segundo nombre" value="" autocomplete="given-name" autofocus="autofocus">
                        </p>
                        <p class="form-row form-row-last validate-required" id="billing_last_name_field" data-priority="20">
                          <input type="text" class="input-text" id="billing_last_name" v-model="payment_data.last_name" placeholder="Ingrese su primer y segundo apellido" value="" autocomplete="family-name">
                        </p>
                        <p class="form-row form-row-wide address-field validate-required" id="billing_address_1_field" data-priority="50">
                          <input type="text" class="input-text" v-model="payment_data.address" id="billing_address" placeholder="Ingrese la dirección" value="" autocomplete="address-line1">
                        </p>
                        <p class="form-row form-row-first validate-required validate-phone" id="billing_phone_field" data-priority="100">
                          <input type="tel" class="input-text" v-model="payment_data.phone" id="billing_phone" placeholder="Ingrese su telefono" value="" autocomplete="tel">
                        </p>
                        <p class="form-row form-row-last validate-required validate-email" id="billing_email_field" data-priority="110">
                          <input type="email" class="input-text" v-model="payment_data.email" id="billing_email" placeholder="Ingrese su correo electrónico">
                        </p>
                        <div>
                          <p><b>Crear contraseña para su cuenta</b></p>
                          <p class="form-row form-row-first validate-required validate-password" id="billing_password_field" data-priority="120">
                            <input type="password" class="input-text" v-model="payment_data.password" id="billing_password" placeholder="Ingrese su contraseña">
                            <i style="display:block;margin-top: 10px;">Ingrese 8 dígitos o más</i>
                            <input style="margin-top:15px;" type="password" class="input-text" v-model="payment_data.password_confirmation" id="password_confirmation" placeholder="Confirme su contraseña">
                          </p>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <h3 id="order_review_heading">Su pedido</h3>
              <div id="order_review" class="woocommerce-checkout-review-order">
                <table class="shop_table woocommerce-checkout-review-order-table">
                  <thead>
                  <tr>
                    <th class="product-name">Producto</th>
                    <th class="product-total">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="cart_item">
                    <td class="product-name">
                      Curso: {{ course_data.name }}
                      <p>{{ course_data.short_description }}</p>
                    </td>
                    <td class="product-total">
                      <span class="woocommerce-Price-amount amount">{{ course_data.price_format }}</span>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                    <tr class="cart-subtotal" v-if="coupon_data.hasOwnProperty('id')">
                      <th>
                        Subtotal <br>
                        Cupón: {{ coupon_data.code }}
                      </th>
                      <td>
                        <span class="woocommerce-Price-amount amount">${{ total_discount }}</span>
                        <span style="text-decoration: line-through;">-${{ Math.abs(Number(total_discount - course_data.price)) }}</span>
                      </td>
                    </tr>
                    <tr class="cart-subtotal" v-if="charges.length">
                      <th>Cargos</th>
                      <td></td>
                    </tr>
                    <tr class="cart_item" v-for="(charge, index) in charges" :key="index">
                      <td class="product-name">
                        {{ charge.label }}
                      </td>
                      <td class="product-total">
                        <span class="woocommerce-Price-amount amount">${{ charge.value.toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr class="order-total">
                      <th>Total</th>
                      <td>
                        <strong><span class="woocommerce-Price-amount amount">${{ total_amount }}</span></strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div id="payment" class="woocommerce-checkout-payment">
                  <ul class="wc_payment_methods payment_methods methods">
                    <li class="wc_payment_method payment_method_cheque" v-for="(payment_method, index) in payment_methods" :key="index">
                      <input :id="('payment_method_' + index)" type="radio" class="input-radio" :value="payment_method.id" v-model="pm_selected" data-order_button_text="">
                      <label :for="('payment_method_' + index)">{{ payment_method.name }}</label>
                    </li>
                  </ul>
                  <div class="woocommerce-info">¿Tiene un cupón?
                    <a href="#" @click.prevent="showcoupon">Haga clic aquí para ingresar su código</a>
                  </div>
                  <form ref="checkout_coupon" @submit.prevent="check_coupon" method="post" style="display: none;">
                    <div class="alert alert-success" role="alert" v-if="coupon_status === 'success'" style=" font-size: 13px; line-height: 1; padding: 17px; border-radius: 1px;">
                      {{ coupon_message }}
                    </div>
                    <div class="alert alert-danger" role="alert" v-if="coupon_status === 'error'" style=" font-size: 13px; line-height: 1; padding: 17px; border-radius: 1px;">
                      {{ coupon_message }}
                      <ul style="margin: 1px;">
                        <li v-for="error in coupon_errors">{{ error[0] }}</li>
                      </ul>
                    </div>
                    <p class="form-row row">
                      <input type="text" v-model="coupon_code" class="input-text col-md-6" placeholder="Código promocional" id="coupon_code" />
                      <input type="submit" class="button" name="apply_coupon" value="Aplicar cupón" style=" padding: 10px; right: -35px;">
                    </p>
                    <div class="clear"></div>
                  </form>
                  <div class="form-row place-order">
                    <input type="submit" class="button alt" name="woocommerce_checkout_place_order" id="place_order" value="Realizar Pedido" data-value="Realizar Pedido">
                  </div>
                </div>
              </div>
            </form>
          </main>
          <div class="d-none d-lg-block divider-70"></div>
        </div>
      </div>
    </section>
    <section class="ls s-py-50" v-else>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="entry-title">Su solicitud ha sido enviada con éxito.</h1>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Detalles del Pedido</h5>
                <div class="card-text">
                  <table class="table table-sm table-borderless">
                    <tbody>
                      <tr>
                        <th>Curso</th>
                        <th><b><a :href="'/curso/' + order_data.invoice.items[0].course_id">{{ order_data.invoice.items[0].course.name }}, {{ order_data.invoice.items[0].course.short_description }}</a></b></th>
                      </tr>
                      <tr>
                        <td>Tipo de Cuenta</td>
                        <td><b>{{ (order_data.invoice.user.user_profile.is_first_time ? 'Primera Vez' : 'Ampliación') }}</b></td>
                      </tr>
                      <tr>
                        <td>Numero de Orden</td>
                        <td><b>{{ order_data.invoice.number }}</b></td>
                      </tr>
                      <tr v-if="order_data.invoice.payments[0]">
                        <td>Método de Pago</td>
                        <td><b>{{ order_data.invoice.payments[0].payment_method.name }}</b></td>
                      </tr>
                      <tr>
                        <td>Fecha</td>
                        <td><strong>{{ order_data.invoice.due_date }}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card mt-30">
              <div class="card-body">
                <h5 class="card-title">Cargos</h5>
                <div class="card-text">
                  <table class="table table-sm table-borderless">
                    <tbody>
                      <tr v-for="(charge, index) in charges" :key="index">
                        <td>{{ charge.label }}</td>
                        <td>${{ charge.value.toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <template v-if="order_data.hasOwnProperty('coupon') && order_data.coupon.hasOwnProperty('id')">
                    <p class="m-0">Subtotal: ${{ order_data.invoice.items[0].course.price_format }}</p>
                    <p class="m-0">Cupón: {{ order_data.coupon.code }} -${{ Math.abs(Number(total_discount - course_data.price)) }}</p>
                  </template>
                  <h6 v-if="order_data.invoice.total_paid === '0.00'" class="m-0">Total a Pagar: {{ order_data.invoice.items[0].course.price_format }}</h6>
                  <h6 v-else class="m-0">Total: {{ total_discount }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="charges.length">
            <div class="card h-100">
              <div class="card-body">
                <h5 class="card-title">Detalles del Cliente</h5>
                <div class="card-text">
                  <table class="table table-sm table-borderless">
                    <tbody>
                      <tr>
                        <th>Nombre</th>
                        <th>{{ order_data.invoice.user.name }}</th>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ order_data.invoice.user.email }}</td>
                      </tr>
                      <tr>
                        <td>Teléfono</td>
                        <td>{{ order_data.invoice.user.user_profile.phone }}</td>
                      </tr>
                      <tr>
                        <td>Dirección</td>
                        <td>{{ order_data.invoice.user.user_profile.address }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card mt-30">
              <div class="card-header"><b>Instrucciones de Pago:</b></div>
              <div class="card-body">
                <ul class="list-styled">
                  <li>1. Realice la transferencia a una de las cuentas que aparecen a continuación.</li>
                  <li>2. Envíe la captura o comprobante de deposito por email o Whatsapp a los datos abajo mencionados.</li>
                  <li>3. Una vez se confirme el pago usted recibirá una notificación con su cuenta activa para comenzar el curso.</li>
                  <li>4. Si su pago fue realizado desde el mismo banco, su cuenta será activada el mismo dia.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-md-12">
            <hr class="mt-0 mb-30">
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <img src="/images/banesco-logo.png" style="width: 155px;" class="d-block mb-20" alt="Logo Banesco">
                <p class="card-text text-dark">
                  Transferencia Bancaria a:<br>
                  <b>Inversiones Maria Isabel</b><br>
                  Cuenta Corriente # <b>1100 0005 7633</b><br>
                  Enviar Comprobante a <b><a href="mailto:info@emmipa.com">info@emmipa.com</a></b><br>
                  Whatsapp <b><a href="https://api.whatsapp.com/send?phone=5076111-0671">+507 6111-0671</a></b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <p class="card-text text-dark">
                  <img src="/images/banco-general-logo.png" style="width: 185px;" class="d-block mb-20" alt="Logo Banco General">
                  Transferencia Bancaria a:<br>
                  <b>Bolivar Noe Fuller Sims</b><br>
                  Cuenta Ahorros # <b>04-23-99-595989-6</b><br>
                  Enviar Comprobante a <b><a href="mailto:info@emmipa.com">info@emmipa.com</a></b><br>
                  WhatsApp <b><a href="https://api.whatsapp.com/send?phone=5076111-0671">+507 6111-0671</a></b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-30">
            <a href="/" class="btn btn-maincolor">Aceptar</a>
          </div>
        </div>
      </div>
    </section>
    <login-modal ref="login_modal" @onLogin="user => (user_data = user)" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import LoginModal from './LoginModal';

export default {
  name: "Checkout",
  components: {LoginModal},
  data: () => ({
    course_selected: '',
    login_url: 'https://www.yoconduzco.net/login',
    course_data: false,
    total_amount: 0,
    charges: [
      {label: 'Procesamiento', value: 0},
      {label: 'Prueba de Dopping', value: 0},
      {label: 'Prueba de Tipaje', value: 0}
    ],
    payment_methods: [],
    pm_selected: false,
    payment_errors: [],
    payment_success: false,
    user_data: {},
    payment_data: {
      is_first_time: true,
      course_id: '',
      charges: [],
      payment_method_id: '',
      country_id: 171,
      identity_card: '',
      first_name: '',
      last_name: '',
      address: '',
      phone: '',
      email: '',
      password: '',
      password_confirmation: ''
    },
    options_countries: [],
    coupon_code: '',
    coupon_message: '',
    coupon_status: '',
    coupon_errors: [],
    coupon_data: '',
    total_discount: 0,
    order_data: {},
  }),
  beforeMount () {
    this.course_selected = Cookies.get('course_selected')
    const user = Cookies.get('user');
    this.user_data = user ? JSON.parse(user) : {}
  },
  mounted () {
    this.get_countries()
    this.get_course()
    this.get_payment_methods()
  },
  methods: {
    get_countries(){
      axios.get('api/get_countries').then(response => {
        this.options_countries = response.data.data
      }).catch(error => {
        console.log(error.response.data)
      })
    },
    get_total () {
      let price = Number(this.course_data.price)
      let charges = 0;
      this.total_discount = 0
      if (this.coupon_data.hasOwnProperty('coupon_type')) {
        if (this.coupon_data.coupon_type.value === 'percentage') {
          const discount = this.coupon_data.discount / 100
          price = Math.max(0, Number(price - (price * discount)).toFixed(2));
          this.total_discount = price.toFixed(2);
        } else if (this.coupon_data.coupon_type.value === 'amount') {
          price = Math.max(0, Number(price - (this.coupon_data.discount)).toFixed(2));
          this.total_discount = price.toFixed(2);
        }
      }

      this.charges.forEach(charge => {
        charges += Number(charge.value)
      })

      this.total_amount = Number(price + charges).toFixed(2);
    },
    get_course () {
      axios.get(`api/course/${this.course_selected}`)
      .then(response => {
        this.course_data = response.data
        this.get_total()
      })
      .catch(error => {
        console.log(error.response.data)
      })
    },
    get_payment_methods () {
      axios.get('api/get_payment_methods')
      .then(response => {
        this.payment_methods = response.data
        this.pm_selected = this.payment_methods[0].id
      })
      .catch(error => {
        console.log(error.response.data)
      })
    },
    showcoupon () {
      $(this.$refs.checkout_coupon).toggle(150)
    },
    check_coupon () {
      axios.post('api/check_coupon', {code: this.coupon_code})
      .then(response => {
        this.coupon_data = response.data.coupon
        this.coupon_status = 'success'
        this.coupon_message = response.data.message
        this.coupon_code = ''
        this.get_total()
      })
      .catch(error => {
        if (error.response.status === 400) {
          this.coupon_status = 'error'
          this.coupon_message = error.response.data.message
          this.coupon_errors = error.response.data.errors
          this.coupon_code = ''
          this.get_total()
        }
      })
    },
    remove_coupon () {
      this.coupon_data = ''
      this.coupon_status = ''
      this.coupon_message = ''
      this.coupon_code = ''
      this.get_total()
    },
    make_payment () {
      this.payment_errors = []
      this.payment_data.course_id = this.course_data.id
      this.payment_data.payment_method_id = this.pm_selected
      this.payment_data.coupon_data = this.coupon_data
      this.payment_data.charges = this.charges
      if (Object.keys(this.user_data).length){
        this.payment_data.user_id = this.user_data.id
      }
      this.$root.$emit('show_preloader');

      axios.post('api/make_payment', this.payment_data)
      .then(response => {
        this.payment_success = true
        this.order_data = response.data.order
        this.$root.$emit('flash_preloader');
        $("html, body").stop().animate({scrollTop: 450}, 500, 'swing');
      })
      .catch(error => {
        if (error.response.status === 400) {
          this.payment_errors = error.response.data
          this.$root.$emit('flash_preloader');
          $("html, body").stop().animate({scrollTop: 450}, 500, 'swing');
        }
      })
    },
    open_login_modal () {
      this.$refs['login_modal'].open_login_modal()
    },
    logout () {
     setTimeout(() => {
       Cookies.remove('token')
       Cookies.remove('user')
       window.location.href = '/checkout'
     }, 500)
    },
  }
}
</script>

<style scoped lang="scss">
.cropper {
  width: auto;
  height: auto;
  background: #18171a;
}
</style>
