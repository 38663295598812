<template></template>
<script>
export default {
  name: "SnowComponent",
  mounted () {
    setInterval(this.createSnowFlake, 100);
  },
  methods: {
    createSnowFlake() {
      const snow_flake = document.createElement('i');
      snow_flake.classList.add('snow');
      snow_flake.classList.add('fa');
      snow_flake.classList.add('fa-snowflake-o');
      snow_flake.style.left = Math.random() * window.innerWidth + 'px';
      snow_flake.style.animationDuration = Math.random() * 3 + 2 + 's'; // between 2 - 5 seconds
      snow_flake.style.opacity = Math.random();
      snow_flake.style.fontSize = Math.random() * 10 + 10 + 'px';

      let snow_container = document.body.appendChild(snow_flake);

      setTimeout(() => {
        snow_flake.remove();
      }, 5000)
    }
  }
}
</script>

<style>
.snow {
  color: white;
  width: 20px;
  height: 20px;
  position: fixed;
  top: -20px;
  animation: fall linear forwards;
  z-index: 99;
}

@keyframes fall {
  to {
    transform: translateY(105vh);
  }
}
</style>
