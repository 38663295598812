<template>
  <div class="modal" tabindex="-1" role="dialog" aria-labelledby="search_modal" id="sign_in_modal">
    <div class="container">
      <div class="row c-gutter-0 align-items-center">
        <div class="ls col-12 col-sm-6">
          <form class="sign-in c-mb-10 c-gutter-10" method="post" @submit.prevent="login">
            <div class="row">
              <div class="col-md-12" v-if="response.type !== ''">
                <div :class="'alert' + ' alert-' + response.type " role="alert">
                  {{  response.message }}
                </div>
              </div>
              <div class="col-sm-12">
                <h4>Iniciar Sesión</h4>
                <div class="divider-sm-0 divider-md-30"></div>
                <div class="form-group has-placeholder">
                  <label for="email">Correo Electrónico <span class="required">*</span></label>
                  <input type="email" aria-required="true" size="30" id="email" v-model="login_data.email" class="form-control" placeholder="Ingrese su correo electrónico">
                </div>
                <div class="form-group has-placeholder">
                  <label for="password">Contraseña<span class="required">*</span></label>
                  <input type="password" aria-required="true" size="30" id="password" v-model="login_data.password" class="form-control" placeholder="Ingrese su contraseña">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <button type="submit" class="btn btn-outline-darkgrey">Iniciar Sesión</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-sm-6 d-none d-sm-block">
          <img src="images/sing-in-modal.jpg" alt="Login Background">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'LoginModal',
  data: () => ({
    fetch_login: false,
    response: {
      type: '',
      message: ''
    },
    show_login_modal: false,
    login_data: {
      email: '',
      password: '',
    }
  }),
  mounted() {
    $('#sign_in_modal').on('hidden.bs.modal', () => {
      this.show_login_modal = false
    })
  },
  watch: {
    show_login_modal: function (val) {
      if (val){
        $('#sign_in_modal').modal('show');
      }else {
        $('#sign_in_modal').modal('hide');
        this.reset_data()
      }
    }
  },
  methods: {
    reset_data() {
      this.response = {
        type: '',
        message: ''
      }
      this.login_data = {
        email: '',
        password: '',
      }
    },
    open_login_modal() {
      this.show_login_modal = true
    },
    login(){
      this.fetch_login = true;
      axios.get('api/csrf-cookie').then(() => {
        axios.post('api/login', this.login_data).then(response => {
          setTimeout(() => {
            this.fetch_login = false
            if (response.data.user.role.slug === 'user'){
              const token = response.data.token
              const user = response.data.user
              this.$emit('onLogin', user)
              Cookies.set('token', token)
              Cookies.set('user', user)
              this.response = {
                type: 'success',
                message: response.data.message
              }
              setTimeout(() => this.show_login_modal = false, 1000)
            }else{
              this.response = {
                type: 'danger',
                message: 'Este usuario no es un estudiante'
              }
            }
          }, 1000)
        }).catch(error => {
          this.fetch_login = false
          this.response = {
            type: 'danger',
            message: error.response.data.message
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.sign-in{
  padding-left: 85px;
  padding-right: 85px;
}
</style>
