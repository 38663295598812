<template>
  <div class="row align-items-center courses c-gutter-40">
    <div class="col-md-12 col-lg-6">
      <div class="item-media">
        <div class="embed-responsive">
          <slot name="course-image"></slot>
        </div>
      </div>
    </div>
    <div class="divider-30 d-lg-none d-md-block"></div>
    <div class="col-md-12 col-lg-6">
      <div class="media-body">
        <div class="courses-header">
          <div class="plan-name">
            <h4>
              {{ course_data.name }}
            </h4>
            <p class="number-lessons mt-10 color-dark">{{ course_data.lessons_count }} Lecciones</p>
          </div>
          <div class="price-wrap color-dark">
            <span class="plan-price">{{ parseInt(course_data.price) }}</span><span class="plan-sign">USD</span>
          </div>
        </div>
        <h6 class="description-lessons my-10">{{ course_data.short_description }}</h6>
        <a :href="'curso/' + course_data.id" class="btn btn-outline-maincolor mt-35">Ver Más</a>
      </div>
    </div>
    <div class="divider-60"></div>
  </div>
</template>

<script>
  export default {
    name: "CourseItemDetail",
    props: ['course_data']
  }
</script>

<style scoped>

</style>