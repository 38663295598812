require('./bootstrap');
require('./compressed')
require('./main')

window.Vue = require('vue');
window.axios = axios.create({
    baseURL: 'https://gateway.yoconduzco.net',
    withCredentials: true,
});

Vue.component('preloader', require('./components/Preloader.vue').default);
Vue.component('course-slider', require('./components/CourseSlider').default);
Vue.component('course-item-detail', require('./components/CourseItemDetail').default);
Vue.component('course-detail', require('./components/CourseDetail').default);
Vue.component('course-detail-title', require('./components/CourseDetailTitle').default);
Vue.component('buy-course-btn', require('./components/BuyCourseBtn').default);
Vue.component('checkout', require('./components/Checkout').default);
Vue.component('snow-component', require('./components/SnowComponent').default);

const app = new Vue({
  el: '#app',
  data: () => ({
    courses: [],
  }),
  mounted(){
    this.get_courses()
  },
  methods: {
    get_courses(){
      axios.get('api/course', {
        params: {sortBy: 'price', descending: 'false'}
      })
      .then(response => {
        this.courses = response.data.data
      })
      .catch(error => {

      })
    }
  }
});
