<template>
  <section class="page_title padding-mobile cs s-pt-30 s-pb-30">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="bold">{{ course_data.name }}</h1>
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Inicio</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/cursos">Cursos</a>
            </li>
            <li class="breadcrumb-item active">
              {{ course_data.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CourseDetailTitle",
  props: ['course_id'],
  data: () => ({
    course_data: {}
  }),
  mounted () {
    this.get_course()
  },
  methods: {
    get_course(){
      axios.get('api/course/' + this.course_id)
      .then(response => {
        this.course_data = response.data;
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>